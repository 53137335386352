import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import Image from 'gatsby-image'
import styled from 'styled-components'
import vars from '../lib/styled'
import { ButtonLink } from './Button'
import Locale, { useLang } from './Locale'

const CardMedia = styled.div`
  display: block;
  cursor: pointer;
  height: ${(props) => (props.large ? '300px' : '200px')};
  position: relative;
  overflow: hidden;
  width: 100%;

  @media (max-width: ${vars.breakpoint.phone}) {
    height: 175px;
  }

  .gatsby-image-wrapper {
    transition: transform 1s ease-in;
    height: 100%;
    width: 100%;
  }
`

const CardContent = styled.div`
  background-color: ${vars.contrast};
  box-shadow: ${vars.boxShadow};
  border-radius: ${vars.borderRadius};
  height: 100%;
  padding: 5vh 3vw;
  position: relative;
  text-align: center;
  transition: transform 750ms;
  width: 100%;
`

const CardContentSansBackground = styled.div`
  border-radius: ${vars.borderRadius};
  height: 100%;
  padding: 0;
  text-align: center;
  transition: transform 750ms;
  width: 100%;
`

const CardWrapper = styled.article`
  padding: 1rem;
  width: ${(props) => (props.fullWidth ? '100%' : '33.33%')};

  @media (max-width: ${vars.breakpoint.laptop}) {
    width: ${(props) => (props.fullWidth ? '100%' : '50%')};
  }

  @media (max-width: ${vars.breakpoint.phone}) {
    padding: 0.25rem;
  }
`

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`

const CardTitle = styled.h2`
  font-size: ${(props) => (props.divider ? '2.027em' : '1.51em')};
  margin-bottom: 1rem;
  margin-top: ${(props) => (props.divider ? '2rem' : '-0')};
  position: relative;

  @media (max-width: ${vars.breakpoint.phone}) {
    font-size: 1.3em;
  }
`

const CardDivider = styled.hr`
  background-color: ${vars.accent};
  border: 0;
  height: 3rem;
  margin: -1.5rem auto 1rem;
  position: relative;
  width: 2px;
`

const WordArt = styled.abbr`
  height: 2.5rem;
  border: 3px solid ${({ lang }) => (lang === 'en-us' ? '#56b245' : '#0075bc')};
  color: ${({ lang }) => (lang === 'en-us' ? '#56b245' : '#0075bc')};
  border-radius: 500px;
  position: absolute;
  top: 1rem;
  right: 1rem;
  line-height: 2.5rem;
  text-align: center;
  width: 2.5rem;
  z-index: 1;
  font-weight: bold;
`

const makeLink = (url) => (url ? () => navigate(url) : undefined)

const CardButton = ({ children, url, ...rest }) =>
  url ? (
    <ButtonLink {...rest} to={url}>
      {children}
    </ButtonLink>
  ) : null

function Card({ id, url, image, name, linkText, glutenFree }) {
  const { locale } = React.useContext(Locale)
  return (
    <CardWrapper>
      <CardContent>
        {glutenFree && (
          <WordArt lang={locale} title="Gluten-Free">
            GF
          </WordArt>
        )}
        <CardBody>
          <div>
            <CardMedia onClick={makeLink(url)}>
              {image && (
                <Image
                  aria-labelledby={id}
                  fluid={image.fluid}
                  imgStyle={{ objectFit: 'contain' }}
                  alt={image.title}
                />
              )}
            </CardMedia>
            <CardTitle id={id}>{name}</CardTitle>
          </div>
          <CardButton url={url} primary>
            {linkText}
          </CardButton>
        </CardBody>
      </CardContent>
    </CardWrapper>
  )
}

Card.propTypes = {
  image: PropTypes.shape({
    title: PropTypes.string,
    fluid: PropTypes.object,
  }),
  linkText: PropTypes.string,
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
  glutenFree: PropTypes.bool,
}

Card.defaultProps = {
  image: {},
  linkText: undefined,
  url: undefined,
  glutenFree: false,
}

const CardSecondary = ({ id, url, image, name, linkText, fullWidth }) => {
  const t = useLang()
  return (
    <CardWrapper fullWidth={fullWidth}>
      <CardContentSansBackground>
        <CardBody>
          <div>
            <CardMedia onClick={makeLink(url)} large>
              {image && (
                <Image
                  aria-labelledby={id}
                  fluid={image.fluid}
                  alt={image.title}
                />
              )}
            </CardMedia>
            <CardDivider aria-hidden={true} />
            <CardTitle id={id}>{name}</CardTitle>
          </div>
          <CardButton aria-label={`${t('viewProducts')}"${name}"`} url={url}>
            <span aria-hidden={true}>{linkText}</span>
          </CardButton>
        </CardBody>
      </CardContentSansBackground>
    </CardWrapper>
  )
}

CardSecondary.propTypes = Card.propTypes
CardSecondary.defaultProps = Card.defaultProps

export default Card
export { CardSecondary }

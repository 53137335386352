import styled, { css } from 'styled-components';
import vars from '../lib/styled';

export const PanelContainer = styled.div`
    background-color: ${props => props.transparent ? 'transparent' : vars.contrast};
    border-radius: ${vars.borderRadius};
    box-shadow: ${props => props.transparent ? 'none' : vars.boxShadow};
    flex-grow: 1;
    margin: 1rem .5rem 0;
    padding: 4vw;
    position: relative;

    ${props => props.withDivider && css`
        &::before {
            background-color: ${vars.accent};
            content: "";
            display: block;
            height: 50px;
            left: 3rem;
            position: absolute;
            top: -30px;
            width: 3px;
        }
    `}
`;

export default styled.div`
    padding: 0 6vw;
    margin: 0 auto;
    width: ${vars.container};

    @media(max-width: ${vars.breakpoint.tablet}){
        padding: 0 .25rem;
    }
`;

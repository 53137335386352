/* eslint-disable indent */
import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

const domain = 'https://marcangelofoods.com/en-ca'

const isString = (s) => typeof s === 'string'
const prependDomain = (canonical) =>
  canonical.startsWith('https://')
    ? canonical
    : canonical.startsWith('/')
    ? domain.concat(canonical)
    : domain.concat('/', canonical)

const CanonicalLink = ({ canonical }) => {
  if (!isString(canonical)) return null

  return (
    <Helmet>
      <link rel="canonical" href={prependDomain(canonical)} />
    </Helmet>
  )
}

CanonicalLink.defaultProps = {
  canonical: null,
}

CanonicalLink.propTypes = {
  canonical: PropTypes.any,
}

export default CanonicalLink

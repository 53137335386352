import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Locale from './Locale';
import gf from '../../static/gf.png';
import gfus from '../../static/gf-us.jpg';

const Img = styled.img`
    float: right;
    bottom: 0;
    width: 105px;
    padding: 1rem;
`;

export default function GlutenBadge({ variants }) {
    const { locale } = React.useContext(Locale);
    let src;
    if (locale !== 'en-us') {
        src = gf;
    } else {
        src = gfus;
    }

    return variants && variants.length
        ? variants.some(({ isGlutenFree }) => isGlutenFree) && (
              <Img src={src} alt="Gluten-Free Product" />
          )
        : null;
}

GlutenBadge.propTypes = {
    variants: PropTypes.arrayOf(
        PropTypes.shape({
            isGlutenFree: PropTypes.bool,
        }),
    ),
};

GlutenBadge.defaultProps = {
    variants: [],
};

import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { generateSlug, capitalizeWord } from '../helpers/slug'
import { extractHTML } from '../helpers/iterators'
import App from '../components/App'
import Hero from '../components/Hero'
import Main from '../components/Main'
import Card from '../components/Card'
import Grid from '../components/Grid'
import Wrapper from '../components/Wrapper'
import Panel, { PanelContainer } from '../components/Panel'
import { HrefLangMeta } from '../components/Locale'
import lang from '../../static/lang.json'
import SEO from '../components/SEO'
import GlutenBadge from '../components/GlutenBadge'
import CanonicalLink from '../components/CanonicalLink'
import styled from 'styled-components'
import vars from '../lib/styled'

const WrapBlockQuote = styled.div`
  blockquote {
    color: ${vars.primary};
  }
`

const Product = ({ data, location }) => {
  const { product, translation } = data
  const ref = lang.products[product.node_locale.toLowerCase()]
  const linkText = lang.productView[product.node_locale.toLowerCase()]
  const cat = product.category.title
  const label = capitalizeWord(ref)

  const breadCrumbs = [
    { href: generateSlug(label), label },
    { href: generateSlug(`${label}/${cat}`), label: cat },
  ]
  return (
    <App>
      <SEO {...product} />
      <CanonicalLink canonical={data.product.canonical} />
      <HrefLangMeta {...translation} sub="products" />
      <Wrapper>
        <HrefLangMeta {...translation} sub="products" />
        <Hero title={product.title} breadcrumbs={breadCrumbs} />
        {data.product.about && (
          <Panel>
            <PanelContainer>
              <GlutenBadge {...product} aria-label="Gluten-Free product" />
              <WrapBlockQuote {...extractHTML(data.product, 'about')} />
              <div style={{ clear: 'both' }} />
            </PanelContainer>
          </Panel>
        )}
        <Main>
          <ProductVariants
            {...product}
            linkText={linkText}
            path={location.pathname}
          />
        </Main>
      </Wrapper>
    </App>
  )
}

const ProductVariants = ({ variants, path, linkText }) => {
  if (!variants) return null
  const variantChildrenProps = variants.map((variant, i) => ({
    key: i,
    image: variant.photo,
    glutenFree: variant.isGlutenFree,
    name: variant.title,
    url: generateSlug(`${path}/${variant.title}`),
    linkText,
  }))

  return (
    <Grid>
      {variantChildrenProps.map((props) => (
        <Card {...props} />
      ))}
    </Grid>
  )
}

Product.propTypes = {
  data: PropTypes.shape({
    translation: PropTypes.object,
    product: PropTypes.object,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

ProductVariants.propTypes = {
  variants: PropTypes.arrayOf(
    PropTypes.shape({
      photo: PropTypes.object,
      title: PropTypes.string,
    }),
  ),
  path: PropTypes.string,
}

ProductVariants.defaultProps = {
  variants: [],
  path: '/',
}

export default Product

export const query = graphql`
  fragment ProductDetail on ContentfulProduct {
    title
    node_locale
    category {
      title
    }
  }

  query getProductByID($contentful_id: String, $node_locale: String) {
    translation: contentfulProduct(
      contentful_id: { eq: $contentful_id }
      node_locale: { ne: $node_locale }
    ) {
      ...ProductDetail
    }

    product: contentfulProduct(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $node_locale }
    ) {
      canonical
      ...ProductDetail
      description {
        description
      }
      about {
        childContentfulRichText {
          html
        }
      }
      variants {
        title
        isGlutenFree
        photo {
          title
          fluid(maxWidth: 400) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`
